@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&family=PT+Sans:wght@700&display=swap');

body, h1, h2, h3, h4, h5 {
  font-family: 'IBM Plex Sans KR', sans-serif !important;
}

:root {
  --vh: 100%;
}

html,
body, #main {
  min-height: var(--vh);
  height: 100%;
}

.gell {
  cursor: pointer;
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}